<template>
  <div class="container">
    <el-tabs v-model="activeName" @tab-click="onTabClick">
      <el-tab-pane
        label="基本信息"
        name="1"
        v-if="reportData.reportcontext[0].typecode === '001'"
      >
        <!--  基本信息表-->
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">姓名</div>
            <div class="value">{{ reportData.reportcontext[0].name }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">生日</div>
            <div class="value">{{ reportData.reportcontext[0].age }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">测评地点</div>
            <div class="value">{{ reportData.reportcontext[0].address }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">您的指导医生</div>
            <div class="value">{{ reportData.reportcontext[0].zdys }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">民族</div>
            <div class="value">{{ reportData.reportcontext[0].mz }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">是否独生子女</div>
            <div class="value">{{ reportData.reportcontext[0].dszn }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">居住条件</div>
            <div class="value">{{ reportData.reportcontext[0].jztj }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">居住方式</div>
            <div class="value">{{ reportData.reportcontext[0].jzfs }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">医疗付费方式</div>
            <div class="value">{{ reportData.reportcontext[0].fffs }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">文化程度</div>
            <div class="value">{{ reportData.reportcontext[0].whcd }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">有无宗教信仰</div>
            <div class="value">{{ reportData.reportcontext[0].zjxy }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">从事职业</div>
            <div class="value">{{ reportData.reportcontext[0].gzzk }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">目前工作状况</div>
            <div class="value">{{ reportData.reportcontext[0].gzzt }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">个人年收入</div>
            <div class="value">{{ reportData.reportcontext[0].grnsr }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">家庭年收入</div>
            <div class="value">{{ reportData.reportcontext[0].jtnsr }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">婚姻状况</div>
            <div class="value">{{ reportData.reportcontext[0].hyzk }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">你对现在婚姻状态满意度</div>
            <div class="value">{{ reportData.reportcontext[0].hhmyzt }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">你对婚后婆媳关系状态满意度</div>
            <div class="value">{{ reportData.reportcontext[0].hhpxzt }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">末次月经时间</div>
            <div class="value">{{ reportData.reportcontext[0].mcyjsj }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">当前怀孕胎次</div>
            <div class="value">{{ reportData.reportcontext[0].hyzs }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">躯体疾病</div>
            <div class="value">{{ reportData.reportcontext[0].qtjb }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="label">不良妊娠</div>
            <div class="value">{{ reportData.reportcontext[0].blrc }}</div>
          </el-col>
        </el-row>
        <!--  基本信息表 end-->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "ViewReportDetails",
  props: {
    reportData: Object,
  },
  data() {
    return {
      activeName: "1",
    };
  },
  created() {
    console.log(this.reportData);
  },
  methods: {
    onTabClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
.container {
}
.label {
  line-height: 30px;
  color: #303133;
}
.value {
  line-height: 30px;
  color: #909399;
}
</style>
