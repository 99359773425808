<template>
	<div class="container">
		<div style="
        margin-top: 10px;
        padding-left: 20px;
        display: flex;
        align-items: center;
      ">
			<el-input placeholder="请输入用户姓名" v-model="customerName" style="width: 280px" clearable>
        <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
			</el-input>
			<el-input placeholder="请输入手机号" v-model="mobileno" clearable style="width: 280px; margin-left: 20px">
				<el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
			</el-input>
      <el-input placeholder="请输入测评医院" v-model="hospitalfullname" clearable style="width: 280px; margin-left: 20px" :disabled="isHospitalAccount">
        <el-button slot="append" icon="el-icon-search" @click="searchList" :disabled="isHospitalAccount"></el-button>
      </el-input>
		</div>
    <div style="padding: 20px; display: flex; align-items: center">
      <el-date-picker
          v-model="dateRange"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="onPickerDateChange">
      </el-date-picker>
      <el-button type="primary" style="margin-left: 20px"
                 @click="exportData">导出</el-button
      >
    </div>
		<div style="margin-top: 20px;margin-bottom: 20px;padding-left: 20px;display: flex;align-items: center;">
      <span style="font-size: 14px; color: #606266;">测评时期筛选：</span>
			<el-radio-group v-model="pregnancyTypeSelect" size="small" @change="changePregnancyType"
				style='padding-right: 40px;'>
				<el-radio-button label="全部"></el-radio-button>
				<el-radio-button label="孕早期-普通"></el-radio-button>
				<el-radio-button label="孕中期-普通"></el-radio-button>
				<el-radio-button label="孕晚期-普通"></el-radio-button>
				<el-radio-button label="产后三天-普通"></el-radio-button>
				<el-radio-button label="产后四十二天-普通"></el-radio-button>
				<el-radio-button label="孕早中期-高级"></el-radio-button>
				<el-radio-button label="孕中晚期-高级"></el-radio-button>
				<el-radio-button label="产后一年内-高级"></el-radio-button>
			</el-radio-group>
		</div>
		<div style="margin-top: 20px;margin-bottom: 20px;
		padding-left: 20px;
		display: flex;
		align-items: center;">
			<span style="font-size: 14px; color: #606266;">报告结果检索：</span>
			<el-radio-group v-model="phq9result" @change="changeResultType" size="small"
				style='padding-right: 40px;'>
				<el-radio-button label="全部"></el-radio-button>
				<el-radio-button label="没有症状"></el-radio-button>
				<el-radio-button label="轻度症状"></el-radio-button>
        <el-radio-button label="中度症状"></el-radio-button>
        <el-radio-button label="中重度症状"></el-radio-button>
				<el-radio-button label="重度症状"></el-radio-button>
			</el-radio-group>
		</div>
    <div id="charts" style="width: 425px; height: 400px"></div>
		<el-table v-loading="loading" :data="tableData" border size="small" style="width: 100%">
			<!-- 手机号 参与评测时间  报告状态-->
			<el-table-column type="index" label="#" width="50"> </el-table-column>
			<el-table-column prop="username" label="姓名" width="100">
			</el-table-column>
			<el-table-column prop="mobileno" label="手机号" width="120">
			</el-table-column>
      <el-table-column label="指导医生" width="120">
        <template slot-scope="scope">
          {{ JSON.parse(scope.row.reportcontext).reportcontext[0].zdys}}
        </template>
      </el-table-column>
			<el-table-column prop="pregnancytypename" label="测评时期" width="120">
			</el-table-column>
			<el-table-column label="测评平台" width="250">
				<template slot-scope="scope">
					{{ scope.row.hospitalfullname || "平台" }}
				</template>
			</el-table-column>
			<el-table-column label="报告状态" width="120">
				<template slot-scope="scope">
					{{ scope.row.reportcontext ? "已完成" : "未完成" }}
				</template>
			</el-table-column>
      <el-table-column label="报告结果" width="180">
        <template slot-scope="scope">
          {{scope.row.phq9result ? scope.row.phq9result : JSON.parse(scope.row.reportcontext).assessgrade}}
        </template>
      </el-table-column>
			<el-table-column prop="createtime" label="报告生成时间" width="150">
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="300">
				<template slot-scope="scope">
					<el-button v-if="scope.row.reportcontext" @click="viewDetails(scope.row)" size="mini">基础信息
					</el-button>
					<el-button @click="viewReport(scope.row)" type="primary" plain size="mini"
						v-if="scope.row.reportcontext">查看报告</el-button>
					<el-button @click="viewAnswerInfo(scope.row)" type="success" plain size="mini"
						v-if="userInfo.userlevel == 0 && rolecode">答题数据</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- <el-table
      v-loading="loading"
      :data="tableData"
      border
      size="small"
      style="width: 100%"
    >
      <el-table-column type="index" label="#" width="50"> </el-table-column>
      <el-table-column prop="username" label="姓名" width="200">
      </el-table-column>
      <el-table-column prop="age" label="生日" width="200"> </el-table-column>
      <el-table-column prop="address" label="评测地点" width="200">
      </el-table-column>
      <el-table-column prop="hyzk" label="婚姻状态" width="200">
      </el-table-column>
      <el-table-column prop="mcyjsj" label="末次月经" width="200">
      </el-table-column>
      <el-table-column
        prop="pregnancytypename"
        label="评测类型"
        min-width="200"
      >
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="300">
        <template slot-scope="scope">
          <el-button @click="viewDetails(scope.row)" size="mini"
            >基础信息</el-button
          >
          <el-button
            @click="viewReport(scope.row)"
            type="primary"
            plain
            size="mini"
            >诊断报告</el-button
          >
          <el-button
            @click="viewAnswerInfo(scope.row)"
            type="success"
            plain
            size="mini"
            >答题数据</el-button
          >
        </template>
      </el-table-column>
    </el-table> -->
		<div class="footer">
			<el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
				:page-sizes="[10, 15, 30]" :page-size="pageSize" layout="total,sizes, prev, pager, next, jumper"
				:total="totalData">
			</el-pagination>
		</div>

		<el-dialog append-to-body title="基础信息" :visible.sync="viewDetailDialog" v-if="viewDetailDialog" width="850px">
			<ViewReportDetails :report-data="viewDetailObj"></ViewReportDetails>
		</el-dialog>

		<el-dialog append-to-body title="答题数据" :visible.sync="viewOriginalDialog" v-if="viewOriginalDialog"
			width="850px">
			<ViewReportOriginal :report-data="viewOriginalObj"></ViewReportOriginal>
		</el-dialog>
	</div>
</template>

<script>
	import api from "../../api";
	import ViewReportDetails from "../../components/ViewReportBasic";
	import router from "../../router";
	import ViewReportOriginal from "../../components/ViewReportOriginal";
  import * as echarts from "echarts";
  import qs from 'qs';

	export default {
		components: {
			ViewReportOriginal,
			ViewReportDetails
		},
		data() {
			return {
				pageIndex: 1,
				pageSize: 15,
				totalData: 0,
				tableData: [],
				viewDetailDialog: false,
				viewDetailObj: null,
				loginData: null,
				viewOriginalDialog: false,
				viewOriginalObj: null,
				customerName: null,
				loading: false,
				mobileno: "",
				userInfo: JSON.parse(localStorage.getItem("logindata")),
				rolecode: false,
				pregnancyTypeSelect: "全部",
				pregnancyTypeCodeSelect: "",
        phq9result: "全部",
        hospitalfullname: '',
        isHospitalAccount: false,
        dateRange: ["",""],
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '本周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '本月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '半年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '本年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
			};
		},
		// watch: {
		// 	customerName() {
		// 		this.initList();
		// 	},
		// 	mobileno(value) {
		// 		if (value == "") {
		// 			this.initList();
		// 		}
		// 	},
		// },
		created() {
			this.loginData = JSON.parse(localStorage.getItem("logindata")) || {};
			let showAll = this.loginData.urls.find((e) => e.url === "report-data"); //是否没有报告全部权限
			if (showAll) this.rolecode = true; //如果code为false则没有报告全部权限
			this.initList();
		},
    mounted() {
      let myChart = echarts.init(
          document.getElementById("charts")
      );
      api.getReportStatistics({
        categorycode: this.loginData.categorycode,
        userlevel: this.loginData.userlevel,
      })
          .then((res) => {
            if (res.data.success === "1") {
              myChart.setOption({
                title: {
                  text: '报告结果检索',
                  left: 'center'
                },
                tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                  show: false,
                  top: "10%",
                  left: 'left'
                },
                series: [
                  {
                    name: 'Access From',
                    type: 'pie',
                    radius: '60%',
                    data: res.data.data.chartsdata,
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                    }
                  }
                ]
              });
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorMsg,
                type: "warning",
              });
            }
          })
          .catch(() => {
            this.$message({
              showClose: true,
              message: "请检查网络连接",
              type: "warning",
            });
          });
    },
    methods: {
      onPickerDateChange(e){
        console.log(e);
        this.dateRange = e;
        this.initList();
      },
      exportData(){
        let params = {
          /**
           * categorycode (当前登录的categorycode) 跟  userlevel(当前登录的userlevel)
           */
          categorycode: this.loginData.categorycode,
          userlevel: this.loginData.userlevel,
          page: this.pageIndex,
          pageSize: this.pageSize,
          username: this.customerName,
          pregnancytypecode:this.pregnancyTypeCodeSelect,
          mobileno: this.mobileno,
          ishospital: 0,
          hospitalfullname: this.hospitalfullname,
        };
        this.isHospitalAccount = localStorage.getItem("ishospital") == 1;
        if (this.isHospitalAccount) {
          params.userlevel = null;
          params.ishospital = 1;
        }
        if (this.dateRange) {
          params.starttime = this.dateRange[0];
          params.endtime = this.dateRange[1];
        }
        switch (this.phq9result){
          case "全部":
            params.phq9result = null;
            break;
          case "没有症状":
            params.phq9result = '没有抑郁症状';
            break;
          case "轻度症状":
            params.phq9result = '疑似有轻微抑郁症状';
            break;
          case "中度症状":
            params.phq9result = '疑似有中度抑郁症状';
            break;
          case "中重度症状":
            params.phq9result = '疑似有中重度抑郁症状';
            break;
          case "重度症状":
            params.phq9result = '疑似有重度抑郁症状';
            break;
        }
        new Promise(resolve=>{
          resolve('/api/as.m.report.exportAssessReportList/1.0.0/action?' + qs.stringify(params));
        }).then(res=>{
          window.location.href = res.replace('http','https');
        })
      },
			initList() {
				this.loading = true;
				let params = {
					/**
					 * categorycode (当前登录的categorycode) 跟  userlevel(当前登录的userlevel)
					 */
					categorycode: this.loginData.categorycode,
					userlevel: this.loginData.userlevel,
					page: this.pageIndex,
					pageSize: this.pageSize,
					username: this.customerName,
					pregnancytypecode:this.pregnancyTypeCodeSelect,
					mobileno: this.mobileno,
					ishospital: 0,
          hospitalfullname: this.hospitalfullname,
				};
				console.log(params);
        this.isHospitalAccount = localStorage.getItem("ishospital") == 1;
				if (this.isHospitalAccount) {
					params.userlevel = null;
					params.ishospital = 1;
				}
        if (this.dateRange) {
          params.starttime = this.dateRange[0];
          params.endtime = this.dateRange[1];
        }
        switch (this.phq9result){
          case "全部":
            params.phq9result = null;
            break;
          case "没有症状":
            params.phq9result = '没有抑郁症状';
            break;
          case "轻度症状":
            params.phq9result = '疑似有轻微抑郁症状';
            break;
          case "中度症状":
            params.phq9result = '疑似有中度抑郁症状';
            break;
          case "中重度症状":
            params.phq9result = '疑似有中重度抑郁症状';
            break;
          case "重度症状":
            params.phq9result = '疑似有重度抑郁症状';
            break;
        }
				api
					.getAccessReport(params)
					.then((res) => {
						this.loading = false;
						if (res.data.success === "1") {
							this.totalData = res.data.data.totalRecords;
							res.data.data.list &&
								res.data.data.list.map((e) => {
									e.details = e.reportcontext ? JSON.parse(e.reportcontext) : {};
								});
							this.tableData = res.data.data.list || [];
						} else {
							this.$message({
								showClose: true,
								message: res.data.errorMsg,
								type: "warning",
							});
						}
					})
					.catch(() => {
						this.loading = false;
						this.$message({
							showClose: true,
							message: "请检查网络连接",
							type: "warning",
						});
					});
			},
			changePregnancyType(e) {
				switch (e) {
					case "孕早期-普通":
						this.pregnancyTypeCodeSelect = "01";
						break;
					case "孕中期-普通":
						this.pregnancyTypeCodeSelect = "02";
						break;
					case "孕晚期-普通":
						this.pregnancyTypeCodeSelect = "03";
						break;
					case "产后三天-普通":
						this.pregnancyTypeCodeSelect = "04";
						break;
					case "产后四十二天-普通":
						this.pregnancyTypeCodeSelect = "05";
						break;
					case "孕早中期-高级":
						this.pregnancyTypeCodeSelect = "06";
						break;
					case "孕中晚期-高级":
						this.pregnancyTypeCodeSelect = "07";
						break;
					case "产后一年内-高级":
						this.pregnancyTypeCodeSelect = "08";
						break;
					default:
						this.pregnancyTypeCodeSelect = "";

				}

				this.pageIndex = 1;
				this.initList();
			},
			changeAdvanceExamType() {
				this.pageIndex = 1;
				this.initList();
			},
			changeResultType() {
				this.pageIndex = 1;
				this.initList();
			},
			searchList() {
				this.pageIndex = 1;
				this.initList();
			},
			viewDetails(row) {
				this.viewDetailObj = row.details;
				this.viewDetailDialog = true;
			},
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.initList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.initList();
			},
			viewReport(val) {
				// const path = "/viewreportdetails";
        console.log(val.qrcodeurl);
				const path = `/echarts/web`;
				let routeUrl = router.resolve({
					path,
					query: {
						reportsid: val.reportid,
						time: val.createtime,
						email: val.email,
						openid: val.openid,
            hospitalfullname:val.hospitalfullname,
            qrcodeurl:val.qrcodeurl
					},
				});
				window.open(routeUrl.href, "_blank");
			},
			viewAnswerInfo(val) {
				this.loading = true;
				api
					.getAssessReportAnswerInfo({
						cusercode: val.cusercode,
						ordercode: val.ordercode,
						assesscode: val.assesscode,
						pregnancytypecode: val.pregnancytypecode,
					})
					.then((res) => {
						this.loading = false;
						console.log(res);
						this.viewOriginalDialog = true;
						this.viewOriginalObj = res.data.data;
					})
					.catch(() => {
						this.loading = false;
						this.$message({
							showClose: true,
							message: "请检查网络连接",
							type: "warning",
						});
					});
			},
		},
	};
</script>

<style scoped>
	.container {
		display: flex;
		flex-direction: column;
		padding: 5px;
	}

	.footer {
		display: flex;
		height: 100px;
		justify-content: center;
		align-items: center;
	}
</style>
